export interface IUser {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    imageUrl: string;
    activated: boolean;
    langKey: any;
    createdBy: string;
    createdDate: any;
    lastModifiedBy: string;
    lastModifiedDate: any;
    authorities: string[];
    pincode: any;
    state: string;
    city: string;
    country: string;
    dob: any;
    fullName: string;
}

export class User implements IUser {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    imageUrl: string;
    activated: boolean;
    langKey: any;
    createdBy: string;
    createdDate: any;
    lastModifiedBy: string;
    lastModifiedDate: any;
    authorities: string[];
    pincode: any;
    state: string;
    city: string;
    country: string;
    dob: any;
    gender: string;
    fullName: string;
    constructor(data) {
        if (!data) return null;
        return {
            id: data.id || '',
            login: data.login || '',
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            imageUrl: data.imageUrl || '',
            activated: data.activated,
            langKey: data.langKey,
            createdBy: data.createdBy || '',
            createdDate: data.createdDate || '',
            lastModifiedBy: data.lastModifiedBy || '',
            lastModifiedDate: data.lastModifiedDate || '',
            authorities: data.authorities,
            pincode: data.pincode,
            state: data.state || '',
            city: data.city,
            country: data.country || '',
            dob: data.dob || '',
            gender: data.gender || '',
            fullName: data.fullName || '',
        }
    }
}