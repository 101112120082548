import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuardService } from './shared/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./after-auth/after-auth.module').then(m => m.AfterAuthModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    // canActivate: [AuthGuardService],
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    // pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top'  },)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
