<header>
    <div class="logo"><img src="../../../assets/images/aq-logo-header.svg" alt="" /></div>
    <div class="hdrRight">
            <!-- <div class="icnlist"> -->
              <!-- <span><img src="../../../assets/images/search-icon.svg" alt=""/></span> -->
              <!-- <span><img src="../../../assets/images/bell-icon.svg" alt=""/></span> -->
            <!-- </div> -->
            <div class="usrName">
                <img [src]="imageUrl || '../../../assets/images/user.svg'" alt=""/>
                <p>Welcome <strong>{{this.userInformation?.fullName}}!</strong></p>
            </div>
            <div class="menubtn">
                <span (click)="openNav()" ><img src="../../../assets/images/header-menu-btn.svg" alt=""/> </span>
            </div>
        </div>

</header>

<div *ngIf="showSidebar" id="mySidenav" class="sidepopup userprofile">
    <div class="overlay"></div>
    <div class="whiteBg">
        <div class="sidebar_head">
            <div class="pt-3 user">
                <img style="height:58px" [src]="imageUrl || '../../../assets/images/user.svg'" alt="" />
                <h4>{{this.userInformation?.fullName}}</h4>
                <!-- <p><a href="#">Edit Profile</a></p> -->
            </div>
            <a class="sideclose" (click)="closeNav()"><img src="../../../assets/images/close-dark.svg" alt="" /> </a>
        </div>
        <div class="boxnav">
            <ul>
                <li class="active"><a style="cursor: pointer;" (click)="navigateToRoute('app/settings')">My Account</a></li>
                <li><a style="cursor: pointer;" (click)="navigateToRoute('app/support')" >Support</a></li>
                <!-- <li><a >Notifications</a></li> -->
                <li><a style="cursor: pointer;" (click)="logoutUser()">Log Out</a></li>
            </ul>
        </div>
    </div>
</div>
