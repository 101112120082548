import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  classToggled = false;
  isLoggedIn = false;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  
  constructor(private router: Router) { }

  ngOnInit(): void {
   if(window.sessionStorage.getItem('jhi-authenticationtoken')) {
    this.isLoggedIn = true;
   } else {
    this.isLoggedIn = false;
   }
  }

  navigateToLogin() {
    this.router.navigate(['account/login']);
  }

}
