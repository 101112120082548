import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  showLoggedInHeader = false;
  constructor() {
   }

  ngOnInit(): void {
    if(window.sessionStorage.getItem('jhi-authenticationtoken')) {
      this.showLoggedInHeader = true;
    } else {
      this.showLoggedInHeader = false;
    }
  }

}
