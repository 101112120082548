import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AfterAuthService } from '../after-auth.service';
import { User, IUser } from '../../auth/auth.model';

@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.scss']
})
export class InnerHeaderComponent implements OnInit {

  userInformation: IUser;
  showSidebar = false;
  imageUrl = '';

  constructor(private router: Router, 
    private afterAuthService: AfterAuthService) {
      this.afterAuthService.getUserImage().subscribe((imageUrl: string) => {
        if(imageUrl) {
          this.imageUrl = imageUrl;
        }
      })
     }

  ngOnInit(): void {
    this.afterAuthService.fetchAccountInformation().subscribe(res => {
      this.userInformation = new User(res);
      this.imageUrl = this.userInformation.imageUrl;
    });
  }

  logoutUser() {
    this.showSidebar = false;
    window.sessionStorage.clear();
    this.router.navigate(['account/login']);
  }

  closeNav() {
    this.showSidebar = false;
  }

  openNav() {
    this.showSidebar = true;
    console.log("open");
  }

  navigateToRoute(route){
    this.showSidebar = false;
    this.router.navigate([route]);
  }


}
