<header>
    <div class="container">
        <div class="logo"><a routerLink="/"><img src="../../../assets/images/aq-logo-header.svg" alt="Ability Quiver" /></a></div>
        <button class="menuBtn" (click)="toggleField()"><i class="a"></i><i class="a"></i><i class="a"></i></button>

        <div class="navigation" [class.active]="classToggled">
            <ul>
                <li><a routerLink="/home">Home</a></li>
                <li><a routerLink="/home/about-us">About Us</a></li>
                <!-- <li><a routerLink="/account/signup">Register</a></li> -->
                <!-- <li><a href="/home/contact-us">Contact Us</a></li> -->
                <li><a routerLink="/home/faqs">FAQ</a></li>
                <li *ngIf="!isLoggedIn"><a routerLink="/account/login">Login</a></li>
                <!-- <li><a (click)="navigateToLogin()" class="btn">Register</a></li> -->
                <li *ngIf="!isLoggedIn"><a routerLink="/account/signup" class="btn">SIGN UP</a></li>
            </ul>
        </div>
    </div>
</header>
