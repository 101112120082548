import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { BundleInfoInterface, IOrder, IOrderPayment } from "./after-auth.model";
import { IUser } from "../auth/auth.model";

@Injectable({providedIn: 'root'})
export class AfterAuthService {

    bundleInformation: BehaviorSubject<BundleInfoInterface>;
    userImageSubject: BehaviorSubject<string>;

    constructor(private http: HttpClient) {
        this.bundleInformation = new BehaviorSubject(null);
        this.userImageSubject = new BehaviorSubject('');
    }

    getBundleInformation() {
        return this.bundleInformation as Observable<BundleInfoInterface>;
    }

    setBundleInformation(bundleInformation) {
        return this.bundleInformation.next(bundleInformation);
    }

    fetchAccountInformation() {
        return this.http.get('api/account');
    }

    searchLocationByPinCode(pinCode) {
        // return this.http.get('api/pincodes/v1/'+pinCode);
        return this.http.get(`api/_search/pincodes?page=0&query=${pinCode}&size=20&sort=id,asc`);
    }

    updateUserProfile(userInfo) {
        return this.http.put('api/users', userInfo);
    }

    updateRecruiterProfile(recruiterInfo, userId) {
        return this.http.put('services/recruiter/api/recruiter-profiles/'+ userId, recruiterInfo);
    }

    postRecruiterProfile(recruiterInfo) {
        return this.http.post('services/recruiter/api/recruiter-profiles', recruiterInfo);
    }

    postCompanyDetails(companyInfo) {
        return this.http.post('services/recruiter/api/companies', companyInfo);
    }

    getProfileDetails(userId) {
        return this.http.get('services/recruiter/api/recruiter-profiles');
    }

    getCompanyDetails(userId) {
        return this.http.get('services/recruiter/api/companies');
    }

    updateCompanyDetails(id, companyInfo) {
        return this.http.put('services/recruiter/api/companies/'+ id, companyInfo);
    }

    updateProfileDetails(id, recruiterInfo) {
        return this.http.put('services/recruiter/api/recruiter-profiles/'+ id, recruiterInfo);
    }

    getAllJobs() {
        return this.http.get('services/recruiter/api/jobs');
    }

    getJobWithJobId(jobId) {
        return this.http.get('services/recruiter/api/jobs/'+ jobId);
    }

    createJobs(jobsBody) {
        return this.http.post('services/recruiter/api/jobs', jobsBody);
    }

    updateJobs(jobsBody, jobId) {
        return this.http.put('services/recruiter/api/jobs/'+ jobId, jobsBody);
    }

    getCompanyDetailForUser() {
        return this.http.get('services/recruiter/api/companies');
    }

    getBundleDetail(body) {
        return this.http.get(`api/bundle?rating=${body.rating}
        &experience=${body.experience}&noticePeriod=${body.noticePeriod}&graduationTier=${body.graduationTier}&city=${body.city}`);
    }

    fetchOrdersForJobId(jobId: string) {
        return this.http.get(`services/checkout/api/orders/job/${jobId}`);
    }

    createOrder(orderInfo: IOrder) {
        return this.http.post(`services/checkout/api/orders`, orderInfo);
    }

    createPayment(paymentInfo: IOrderPayment) {
        return this.http.post('services/checkout/api/payments', paymentInfo);
    }

    fetchOrdersForOrderId(orderId) {
        return this.http.get('services/checkout/api/orders/'+ orderId);
    }

    fetchPayments(){
        return this.http.get('services/checkout/api/payments');
    }

    downloadTransactionInvoice(orderId: string) {
        return this.http.get(`services/checkout/api/orders/${orderId}/invoice`, { responseType: 'blob' });
    }

    fetchAllOrders() {
        return this.http.get('services/checkout/api/orders');
    }

    updateOrder(orderInfo: IOrder) {
        return this.http.put(`services/checkout/api/orders/${orderInfo.id}`, orderInfo);
    }

    changePassword(param){
        return this.http.put('api/account/set-password', param);
    }

    fetchRecruiterStats() {
        let mockResponse = {
            "totalStats": {
                "total_assigned_candidates": 1,
                "total_hired_candidates": 0,
                "total_shortlisted_candidates": 0,
                "total_rejected_candidates": 0
            },
            "annualStats": {
                "JANUARY": {
                    "total_assigned_candidates": 10,
                    "total_hired_candidates": 20,
                    "total_shortlisted_candidates": 15,
                    "total_rejected_candidates": 8
                },
                "JUNE": {
                    "total_assigned_candidates": 20,
                    "total_hired_candidates": 10,
                    "total_shortlisted_candidates": 7,
                    "total_rejected_candidates": 5
                },
                "MAY": {
                    "total_assigned_candidates": 10,
                    "total_hired_candidates": 6,
                    "total_shortlisted_candidates": 20,
                    "total_rejected_candidates": 2
                },
                "OCTOBER": {
                    "total_assigned_candidates": 30,
                    "total_hired_candidates": 20,
                    "total_shortlisted_candidates": 10,
                    "total_rejected_candidates": 40
                },
                "DECEMBER": {
                    "total_assigned_candidates": 40,
                    "total_hired_candidates": 0,
                    "total_shortlisted_candidates": 10,
                    "total_rejected_candidates": 0
                },
                "MARCH": {
                    "total_assigned_candidates": 20,
                    "total_hired_candidates": 10,
                    "total_shortlisted_candidates": 30,
                    "total_rejected_candidates": 0
                },
                "FEBRUARY": {
                    "total_assigned_candidates": 0,
                    "total_hired_candidates": 20,
                    "total_shortlisted_candidates": 10,
                    "total_rejected_candidates": 30
                },
                "AUGUST": {
                    "total_assigned_candidates": 30,
                    "total_hired_candidates": 0,
                    "total_shortlisted_candidates": 20,
                    "total_rejected_candidates": 10
                },
                "JULY": {
                    "total_assigned_candidates": 40,
                    "total_hired_candidates": 0,
                    "total_shortlisted_candidates": 20,
                    "total_rejected_candidates": 10
                },
                "SEPTEMBER": {
                    "total_assigned_candidates": 0,
                    "total_hired_candidates": 20,
                    "total_shortlisted_candidates": 30,
                    "total_rejected_candidates": 20
                },
                "NOVEMBER": {
                    "total_assigned_candidates": 30,
                    "total_hired_candidates": 10,
                    "total_shortlisted_candidates": 0,
                    "total_rejected_candidates": 20
                },
                "APRIL": {
                    "total_assigned_candidates": 20,
                    "total_hired_candidates": 40,
                    "total_shortlisted_candidates": 30,
                    "total_rejected_candidates": 0
                }
            },
            "jobByTitleStats": {
                "asdad": {
                    "total_assigned_candidates": 10,
                    "total_hired_candidates": 20,
                    "total_shortlisted_candidates": 30,
                    "total_rejected_candidates": 50
                }
            }
        };
        // return of(mockResponse);
        return this.http.get('services/recruiter/api/recruiter-profiles/stats');
    }

    fetchApplicantsList(userId){
        userId = 1;
        return this.http.get('services/candidate/api/candidate-profile/assign?recruiterId='+ userId);
    }

    postFeedback(body){
        return this.http.post('services/recruiter/api/supports', body);
    }

    getApplicantsById(userId, id){
        userId = 1;
        let mockResponse = {
            "id" : 1,
    "recruiterId" : 1,
    "jdId" : 1,
    "candidateId" : 1,
    "aqRating" : 4.2,
    "firstName" : "FName1",
    "middleName" : "MiddleName1",
    "lastName" : "LastName1",
    "mobileNumber" : "9854258754",
    "email" : "test@aq.com",
    "employerName" : "test",
    "noticePeriod" : 30,
    "jobTitle" : "asdad",
    "workExperience" : 18,
    "qualification" : "cde",
    "resume" : "SampleResume",
    "videoUrl" : "http://hdkhsakj.asdkj.aslkdj",
    "status" : "ASSIGNED"
        }
        // return of(mockResponse);
        return this.http.get(`services/candidate/api/candidate-profile/assign?recruiterId=${userId}&candidateId=${id}`);
    }

    uploadFile(formData: FormData){
        return this.http.post('services/eventproducer/api/files', formData);
    }

    getUserInformation(){
        return this.http.get('api/users');
    }

    updateUserInformation(user: IUser) {
        return this.http.put('api/users', user);
    }

    setUserImage(imageUrl: string): void {
        this.userImageSubject.next(imageUrl);
    }

    getUserImage(): Observable<string> {
        return this.userImageSubject as Observable<string>;
    }

    updateCandidateStatus(body) {
      return this.http.put(`services/candidate/api/candidate-profile/assign/${body.id}`, body);
    }
}
