import { CanActivate, CanActivateChild, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";

@Injectable({
    providedIn: 'root',
  })
export class AuthGuardService implements CanActivate {
    constructor(private localStorage: LocalStorageService,
         private sessionStorage: SessionStorageService,
         private router: Router) {
    }

    canActivate(): boolean {
      
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        // console.log("token :: ", token, this.sessionStorage, this.localStorage);
        if (!token) {
          this.router.navigate(['account/login']);
          return false;
        }
        return true;
      }
}