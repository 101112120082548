import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { tap, map, catchError } from "rxjs/operators";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";

@Injectable()
export class HttpInterceptorsService implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private notifierService: NotifierService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   if (request.url.includes('v1/contact-us')) {
      request = request.clone({
        url: environment.notificationUrl + request.url,
      });
    } else {
      request = request.clone({
        url: environment.baseUrl + request.url,
      });
    }

    const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do nothing for now
            if (event.status === 201 || event.status === 200) {
              if (!event.body) {
                event = event.clone({ body: { success: true } });
              }
              return event;
            }
            return event;
          }
          if (event instanceof HttpErrorResponse) {
            if (event.status === 401 || event.status === 403) {
              // alert('You don\'t have permission to view this page');
              window.sessionStorage.clear();
              this.router.navigate(['account/login']);
            }
          }
        }),
        catchError(err => {
          if (err.status === 401 || err.status === 403) {
            if(!request.url.includes('authenticate')) {
              alert('You don\'t have permission to view this page');
            }
            window.sessionStorage.clear();
            this.router.navigate(['account/login']);
            // return of(err);
          }
          return throwError(err);
        }));
  }
}